import { FC, ReactNode, useEffect, useState } from "react";
import { Form, Input } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import { deleteEntity, getEntities, getEntity, regionItemByCode } from "./assessment.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { ModalForm, ProFormDependency, ProFormRadio, ProFormSelect, ProFormSwitch } from '@ant-design/pro-form';
import ProFormDatePickerMonth from "@ant-design/pro-form/lib/components/DatePicker/MonthPicker";
import moment from "moment";
import ProFormDatePickerYear from "@ant-design/pro-form/lib/components/DatePicker/YearPicker";

export interface dealerInfoListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const DealerInfoList: FC<dealerInfoListProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const [areaVisible, setAreaVisible] = useState(false);
  const [searchOnValuesChange, setSearchOnValuesChange] = useState(null);
  const [registArea, setRegistArea]: any = useState({});
  const [giftArea, setGiftArea]: any = useState({});
  const [activityReissueList, setActivityReissueList]: any = useState([]);
  const [defaultDate, setDefaultDate]: any = useState(null);
  const [clearFormFields,setClearFormFields]= useState<string[]>([]);

  useEffect(() => {
    const dateFormat = 'YYYY-MM';
    let data: any = {}
    let date = new Date()
    let resultTime = moment(date).format(dateFormat)
    data['time.greaterThanOrEqual'] = resultTime + '-01 00:00:00'
    data['time.lessThanOrEqual'] = resultTime + '-31 23:59:59'
    setDefaultDate(resultTime)
    setSearchOnValuesChange(data)
  }, []);


  const columns: ProColumns<any>[] = [{
    title: '授权号',
    dataIndex: 'code',
    width: 60
  },
  {
    title: '姓名',
    dataIndex: 'name',
    width: 60
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    width: 100
  },
  {
    title: '美人计',
    dataIndex: 'mrjRegion',
    width: 80,
    render: ((res: any, record: any) => {
      return <span className={record.mrjRegion === '无区域' ? 'gray' : ''} onClick={() => {
        if (record.mrjRegion === '无区域') {
          return false
        }
        regionItemByCode({
          code: record.code
        }).then((e) => {
          if (e.length > 0) {
            let register0 = e.find((item: any) => item.regionCodeType === 0 && item.regionType === 'register_region')
            let register1 = e.find((item: any) => item.regionCodeType === 1 && item.regionType === 'register_region')
            setRegistArea(register1 || register0)

            let purchase0 = e.find((item: any) => item.regionCodeType === 0 && item.regionType === 'purchase_region')
            let purchase1 = e.find((item: any) => (item.regionCodeType === 1 || item.regionCodeType === 2) && item.regionType === 'purchase_region')
            setGiftArea(purchase1 || purchase0)
          }
        })
        setAreaVisible(true)
      }}>{record.mrjRegion}</span>
    })
  },
  {
    title: '黑天鹅',
    dataIndex: 'hteRegion',
    width: 100,
    render: ((res: any, record: any) => {
      return <span className={record.hteRegion === '无区域' ? 'gray' : ''} onClick={() => {
        if (record.hteRegion === '无区域') {
          return false
        }
        regionItemByCode({
          code: record.code
        }).then((e) => {
          if (e.length > 0) {
            let register0 = e.find((item: any) => item.regionCodeType === 0 && item.regionType === 'register_region')
            let register2 = e.find((item: any) => item.regionCodeType === 2 && item.regionType === 'register_region')
            setRegistArea(register2 || register0)

            let purchase0 = e.find((item: any) => item.regionCodeType === 0 && item.regionType === 'purchase_region')
            let purchase2 = e.find((item: any) => (item.regionCodeType === 1 || item.regionCodeType === 2) && item.regionType === 'purchase_region')
            setGiftArea(purchase2 || purchase0)
          }
        })
        setAreaVisible(true)
      }}>{record.hteRegion}</span>
    })
  }, {
    title: '朱丽安娜女王',
    dataIndex: 'bqRegion',
    width: 100,
    render: ((res: any, record: any) => {
      return <span className={record.bqRegion === '无区域' ? 'gray' : ''} onClick={() => {
        if (record.bqRegion === '无区域') {
          return false
        }
        regionItemByCode({
          code: record.code
        }).then((e) => {
          if (e.length > 0) {
            let register0 = e.find((item: any) => item.regionCodeType === 0 && item.regionType === 'register_region')
            let register3 = e.find((item: any) => item.regionCodeType === 3 && item.regionType === 'register_region')
            setRegistArea(register3 || register0)

            let purchase0 = e.find((item: any) => item.regionCodeType === 0 && item.regionType === 'purchase_region')
            let purchase3 = e.find((item: any) => item.regionCodeType === 3 && item.regionType === 'purchase_region')
            setGiftArea(purchase3 || purchase0)
          }
        })
        setAreaVisible(true)
      }}>{record.bqRegion}</span>
    })
  },
  // {
  //   title: '月份',
  //   dataIndex: 'orgType',
  //   width: 60,
  //   render: ((res: any, record: any) => {
  //     return orgTypeMap[record.orgType]
  //   })
  // },
  {
    title: '视频打卡',
    dataIndex: 'videoSignNum',
    width: 80,
  }, {
    title: '沙龙次数',
    dataIndex: 'salonNum',
    width: 80
  }, {
    title: '奖励发放',
    dataIndex: 'isSatisfy',
    width: 80,
  }, {
    title: '是否补发',
    dataIndex: 'isReissue',
    width: 70,
    render: ((res: any, record: any) => {
      return <span onClick={() => {
        setActivityReissueList(record.activityReissueList || [])
        setVisible(true)
      }}>{record.isReissue}</span>
    })
  }];


  const searchFields = [{
    label: '授权号',
    name: 'code.equals',
  }, {
    label: '用户姓名',
    name: 'name.equals',
  }, {
    label: '手机号',
    name: 'phone.equals',
  }];



  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
          width='550px'
        />
        <ProFormRadio.Group
          name={'timeType'}
          initialValue={'month'}
          radioType="button"
          options={[
            {
              label: '年份',
              value: 'year',
            }, {
              label: '月份',
              value: 'month',
            }
          ]}
        />
        <ProFormDependency name={['timeType']}>
          {({ timeType }) => {
            return timeType === 'year' ? <ProFormDatePickerYear
              name={'timeYear'}
              placeholder="年份"
            /> : <ProFormDatePickerMonth
              name={'time'}
              placeholder="月份"
            />
          }}
        </ProFormDependency>
      </Input.Group>
    </Form.Item>
  );


  const reissueRecordsColumns: ProColumns<any>[] = [{
    title: '超额月份',
    dataIndex: 'time',
    width: 70,
    ellipsis: true,
  }, {
    title: '视频打卡补充次数',
    dataIndex: 'videoSignNum',
    width: 80,
    ellipsis: true,
  }, {
    title: '沙龙会议补充次数',
    dataIndex: 'salonNum',
    width: 70,
    ellipsis: true,
  }]

  //导出
  const exportData = (resData?: any) => {
    let orderData: any[] = [];
    const exportOrderData = resData ? resData : props.entities;
    exportOrderData.forEach((res: any) => {
      let arr: any = {
        ...res
      };
      orderData.push(arr);
    });
    return orderData;
  }



  return (
    <>
      {searchOnValuesChange && <ListViewPage<any>
        columns={columns}
        clearFormFields={clearFormFields}
        downloadEntities={exportData()}
        downloadAllEntities={exportData}
        showDownload
        hideCreateButton
        actionColumnWidth={150}
        permanentParams={searchOnValuesChange}
        fetchSearchParams={(e: any) => {
          setSearchOnValuesChange(e)
        }}
        onValuesChange={(e: any) => {
          let data = e

          if(data.timeType === 'year'){
            setClearFormFields(['time']);
            if (data.timeYear) {
              const dateFormat = 'YYYY';
              let year = moment(new Date(data.timeYear)).format(dateFormat)
              data['time.greaterThanOrEqual'] = year + '-01-01 00:00:00'
              data['time.lessThanOrEqual'] = year + '-12-31 23:59:59'
            } else {
              data['time.greaterThanOrEqual'] = undefined
              data['time.lessThanOrEqual'] = undefined
            }
          }else{
            setClearFormFields(['timeYear']);
            if (data.time) {
              const dateFormat = 'YYYY-MM';
              let resultTime = moment(new Date(data.time)).format(dateFormat)
              data['time.greaterThanOrEqual'] = resultTime + '-01 00:00:00'
              data['time.lessThanOrEqual'] = resultTime + '-31 23:59:59'
            } else {
              data['time.greaterThanOrEqual'] = undefined
              data['time.lessThanOrEqual'] = undefined
            }
          };
          data.time = undefined
          data.timeYear = undefined
          setSearchOnValuesChange(data)
        }}
        hideDeleteButton
        hideActionColumn
        quickFilterPanel={quickFilterPanel}
        detailsIndex={0}
        {...props}
      >
        {props.children}
      </ListViewPage>
      }
      <ModalForm
        title={'补发详情'}
        width={500}
        visible={visible}
        onVisibleChange={(e) => !e && setVisible(false)}
        modalProps={{
          footer: false,
          wrapClassName: 'accountcancellation'
        }}
      >
        <ProTable
          className="payTable"
          bordered
          options={false}
          search={false}
          columns={reissueRecordsColumns}
          dataSource={activityReissueList}
          pagination={false}
        />
      </ModalForm>

      <ModalForm
        title={'区域详情'}
        width={500}
        visible={areaVisible}
        onVisibleChange={(e) => !e && setAreaVisible(false)}
        modalProps={{
          footer: false,
          wrapClassName: 'accountcancellation'
        }}
      >
        <div className="area-wrapper">
          <div className="row">
            <div className="atitle">注册地址</div>
            <div className="atext">{registArea?.provinceName}-{registArea?.cityName}-{registArea?.districtName}</div>
          </div>
          <div className="row">
            <div className="atitle">赠送区域</div>
            <div className="atext">{giftArea?.provinceName}-{giftArea?.cityName}-{giftArea?.districtName}</div>
          </div>
        </div>

      </ModalForm>
    </>

  );
};

const mapStateToProps = ({ assessment }: RootState) => ({
  entity: assessment.entity,
  entities: assessment.entities,
  loading: assessment.loading,
  updateSuccess: assessment.updateSuccess,
  createSuccess: assessment.createSuccess,
  total: assessment.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DealerInfoList);