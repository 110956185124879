import { FC } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { deleteEntity, getEntities, getEntity } from "./platform.reducer";
import { ProFormText, ProFormSelect, ModalForm } from '@ant-design/pro-form';
import { RouteComponentProps, navigate } from "@reach/router";
import { Decorate } from "model/decorate/decorate.model";
import { Switch, Popconfirm, message, Input, Button } from 'antd';
import { updateUseState, examPageTypeList } from '../api';
import PageType from '../examcomponents/pageType'


export interface DecorateListProps extends StateProps, DispatchProps, RouteComponentProps { }

const columns: ProColumns<Decorate>[] = [{
  title: 'Id',
  dataIndex: 'id',
  valueType: 'text',
  align: 'center'
}, {
  title: '页面名称',
  dataIndex: 'title',
  valueType: 'text',
  align: 'center',
  render: (text, record: Decorate) => {
    return record.title
  }
}, {
  title: '页面类型',
  dataIndex: 'type',
  valueType: 'text',
  align: 'center'
},{
  title: '完成考试人数',
  dataIndex: 'finishExamNum',
  valueType: 'text',
  align: 'center',
  render: (text, record:any) => {
    return record.examItemVO.finishExamNum || 0
  }
},{
  title: '是否重试 ',
  dataIndex: 'isRetry',
  valueType: 'text',
  align: 'center',
  render: (text, record:any) => {
    return record.examItemVO.isRetry === 1 ? '是': '否'
  }
},{
  title: '重试次数',
  dataIndex: 'retryNum',
  valueType: 'text',
  align: 'center',
  render: (text, record:any) => {
    return record.examItemVO.retryNum
  }
}]

const RenderCopyH5Link: FC<{ record: any }> = ({ record }) => {
  return <div className="copy-link" onClick={() => {
    let pageId = record.id
    let str = ''
    message.success("复制成功");
    const env = process.env.REACT_APP_ENV || '';
    const prodEnvironment = ['pre', 'prod'].indexOf(env) !== -1;//是否正式环境
    if (!prodEnvironment) {
      str = `https://h5-test.merrige.cn/H5/index.html#/pages/home/examh5Link?pageId=${pageId}`
    } else {
      str = `https://app.merrige.cn/H5/index.html#/pages/home/examh5Link?pageId=${pageId}`
    }
    return navigator?.clipboard?.writeText(str).catch((err) => {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    });
  }}>复制链接</div>
}

const RenderSwitchState: FC<{ record: any }> = ({ record }) => {
  return <div className="switch-container">
    <Popconfirm title={`确认${!record.isUser === true ? '使用' : '取消使用'}“${record.title}”页？`} onConfirm={
      (e) => {
        updateUseState({
          id: record.id,
          isUser: !record.isUser
        }).then((res: any) => {
          if (res.data) {
            record.isUser = !record.isUser
            message.success(`页面${!record.isUser ? '取消使用' : '使用'}成功`);
          }
        })
      }
    }>
      <Switch checkedChildren="启用" onChange={
        (e) => {
        }
      } unCheckedChildren="关闭" checked={record.isUser} />
    </Popconfirm>
  </div>
}

const DecorateList: FC<DecorateListProps> = (props: any) => {

  // 筛选条件
  const quickFilterPanel = (
    <div className="quick-filter" style={
      {
        display: 'flex',
        paddingRight: '10px'
      }
    }>
      <Input.Group compact>
        <ProFormText name='title.equals' placeholder='请输入页面名称' />
        <div className="wd10"></div>
        <ProFormSelect  request={async (params) => {
          const data: any = await examPageTypeList();
          return data.data.map((item: any) => ({
            value: item?.id,
            label: item?.name,
          }))
        }} name='type.equals' placeholder='请选择页面类型' />
      </Input.Group>
    </div>
  );

  const additionActions = [
    <ModalForm
      width={600}
      title="添加或修改页面类型"
      trigger={<Button type="primary" icon={<></>}>添加或修改页面类型</Button>}
      modalProps={{ destroyOnClose: true, className: 'addpage-type' }}
      onFinish={async (values) => { }}>
        <PageType />
      </ModalForm>
  
  ]


  return (
    <ListViewPage<Decorate>
      actions={additionActions}
      columns={columns}
      // detailPage={<ListDetails  columns={columns} />}
      hideDeleteButton={false}
      hideEditButton={false}
      quickFilterPanel={
        quickFilterPanel
      }
      actionColumnWidth={160}
      rowActions={[(dom, record: any) => {
        return <RenderCopyH5Link record={record} />
      },
      (dom, record: any) => {
        return <RenderSwitchState record={record} />
      }
      ]}
      clickRowHandle={(id) => { navigate('/examMng/examDecorate/' + id + '/edit') }}
      {...props}

    >
      {props.children}
    </ListViewPage >
  );
};


const mapStateToProps = ({ examDecorate }: RootState) => ({
  entity: examDecorate.entity,
  entities: examDecorate.entities,
  loading: examDecorate.loading,
  updateSuccess: examDecorate.updateSuccess,
  total: examDecorate.total,
  createSuccess: examDecorate.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(DecorateList)